import useAPI, { Form } from './useAPI';
import React from 'react';

const useObjectTypes = () => {
  const { getObjectTypes } = useAPI();

  const [ objectTypes, setObjectTypes ] = React.useState<Form[]>([]);

  React.useEffect(() => {
    const cache = sessionStorage.objectTypes;
    if (cache) {
      setObjectTypes(JSON.parse(cache));
    } else {
      getObjectTypes().then((data) => {
        setObjectTypes(data);
        sessionStorage.objectTypes = JSON.stringify(data);
      });
    }
  }, []);

  return objectTypes;
};

export default useObjectTypes;
