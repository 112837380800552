import * as React from 'react';
import useAPI, { Auction, Supply } from '../../../Hooks/useAPI';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntervalWhen } from 'rooks';
import { Currency } from '../../../InputMask/Currency';
import { setAuctionProcessing } from '../../../store/apiSlice';
import getSymbolFromCurrency from 'currency-symbol-map';
import { parseCurrencyAmount } from '../../../parseCurrencyAmount';

type Props = {
  disabled: boolean;
  auction: Auction;
  supply: Supply;
  onBidError: () => void;
};

const czk = 1;
const eur = 2;
const pln = 3;

const prodej = 1;
const najem = 2;
const insolvence = 3;

const majetekKategorieA = 11;
const majetekKategorieB = 12;

const minValueMap = new Map<number, { [currency: number]: number } | { [category: number]: { [currency: number]: number } }>(
  [
    [prodej, {
      [czk]: 10000,
      [eur]: 400,
      [pln]: 2000,
    }],
    [najem, {
      [czk]: 100,
      [eur]: 4,
      [pln]: 20,
    }],
    [insolvence, {
      [majetekKategorieA]: {
        [czk]: 1000,
        [eur]: 40,
        [pln]: 200,
      },
      [majetekKategorieB]: {
        [czk]: 100,
        [eur]: 4,
        [pln]: 20,
      },
    }],
  ],
);
const maxValueMap = new Map<number, { [currency: number]: number } | { [category: number]: { [currency: number]: number } }>(
  [
    [prodej, {
      [czk]: 1000000,
      [eur]: 4000,
      [pln]: 20000,
    }],
    [najem, {
      [czk]: 1000,
      [eur]: 40,
      [pln]: 200,
    }],
    [insolvence, {
      [majetekKategorieA]: {
        [czk]: 1000000,
        [eur]: 40000,
        [pln]: 200000,
      },
      [majetekKategorieB]: {
        [czk]: 100000,
        [eur]: 4000,
        [pln]: 20000,
      },
    }],
  ],
);
const roundedToMap = new Map<number, { [currency: number]: number } | { [category: number]: { [currency: number]: number } }>(
  [
    [prodej, {
      [czk]: 1000,
      [eur]: 10,
      [pln]: 100,
    }],
    [najem, {
      [czk]: 100,
      [eur]: 1,
      [pln]: 10,
    }],
    [insolvence, {
      [majetekKategorieA]: {
        [czk]: 1000,
        [eur]: 10,
        [pln]: 100,
      },
      [majetekKategorieB]: {
        [czk]: 100,
        [eur]: 1,
        [pln]: 10,
      },
    }],
  ],
);

export default function Bid(props: Props) {
  const dispatch = useDispatch();

  const { disabled, auction } = props;
  const { aukceId } = useParams();

  const { keycloak } = useKeycloak();

  const { bidAuction } = useAPI();

  const [ countDown, setCountDown ] = React.useState('');
  const [ showCountDown, setShowCountDown ] = React.useState(false);
  const [ open, setOpen ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);
  const [ value, setValue ] = React.useState('');

  useIntervalWhen(() => {
    const x = dayjs(props.auction.endTimestamp);
    const y = dayjs();

    const val = dayjs.duration(x.diff(y));

    setShowCountDown(val.asSeconds() <= 21);

    const output = [
      val.days() > 0 ? `${val.days()}d` : null,
      val.hours() > 0 ? `${val.hours()}h` : null,
      val.minutes() > 0 ? `${val.minutes()}m` : null,
      `${val.seconds()}s`,
    ].filter(Boolean).join(' ');
    setCountDown(output);
  }, 1000, true, true);

  const userTriggeredBidButton = React.useCallback(() => {
    if (!keycloak.authenticated) {
      return false;
    }
    setLoading(true);
    bidAuction(aukceId as string, auction.uuid!, Number(value))
      .catch(err => {
        if (err.response.data.error === 'after_end') {
          props.onBidError();
        }
        console.log({ err });
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
        dispatch(setAuctionProcessing());
      });
  }, [bidAuction, setOpen, aukceId, value, keycloak.authenticated, setAuctionProcessing, dispatch]);

  const validatedValue = React.useMemo(() => {
    if (value === '0' || value.length === 0) {
      return 'Povinné';
    }

    let minValue : number;
    let maxValue : number;
    let roundedTo : number;
    const minValueObject = minValueMap.get(props.supply.contractType.id);
    const maxValueObject = maxValueMap.get(props.supply.contractType.id);
    const roundedToObject = roundedToMap.get(props.supply.contractType.id);

    if (!minValueObject || !maxValueObject || !roundedToObject) {
      return 'Neočekávaná chyba';
    }

    if (props.supply.contractType.id === insolvence) {
      minValue = (minValueObject[props.supply.objectType.id] as { [currency: number]: number; })[props.supply.currency.id];
      maxValue = (maxValueObject[props.supply.objectType.id] as { [currency: number]: number; })[props.supply.currency.id];
      roundedTo = (roundedToObject[props.supply.objectType.id] as { [currency: number]: number; })[props.supply.currency.id];
    } else {
      minValue = minValueObject[props.supply.currency.id] as number;
      maxValue = maxValueObject[props.supply.currency.id] as number;
      roundedTo = roundedToObject[props.supply.currency.id] as number;
    }

    if (!minValue || !maxValue || !roundedTo) {
      return 'Neočekávaná chyba';
    }

    if (Number(value) % roundedTo !== 0) {
      return `Prosím zadejte částku zaokrouhlenou na ${roundedTo} ${getSymbolFromCurrency(props.supply.currency.label)}`;
    }

    if (Number(value) < minValue) {
      return `Částka musí být alespoň ${parseCurrencyAmount(minValue, props.supply.currency.label)}`;
    }

    if (Number(value) > maxValue) {
      return `Částka nesmí přesáhnout ${parseCurrencyAmount(maxValue, props.supply.currency.label)}`;
    }

    if (props.supply.contractType.label === 'Prodej' && value < (auction as any).bid_amount) {
      return `Částka musí být alespoň ${parseCurrencyAmount((auction as any).bid_amount, props.supply.currency.label)}`;
    }

    return false;
  }, [value, auction]);

  return (<>
    <Button variant='contained' color='secondary' disabled={disabled} sx={{
      width: 'fit-content', alignSelf: 'center', minWidth: '200px', height: '40px', m: 0,
    }} onClick={() => {
      let minValue : number;
      const minValueObject = minValueMap.get(props.supply.contractType.id);

      if (!minValueObject) {
        return 'Neočekávaná chyba';
      }

      if (props.supply.contractType.id === insolvence) {
        minValue = (minValueObject[props.supply.objectType.id] as { [currency: number]: number; })[props.supply.currency.id];
      } else {
        minValue = minValueObject[props.supply.currency.id] as number;
      }

      if (!minValue) {
        return 'Neočekávaná chyba';
      }

      setValue(String(minValue));
      setOpen(true);
    }
    }>
      { disabled
        ? 'Vaše nabídka je nejvyšší'
        :' PŘIHODIT'}</Button>
    <Dialog open={open} fullWidth>

      <DialogTitle component='span'>
        <Typography variant={'h6'}>Opravdu si přejete přihodit?</Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography>Zadejte částku jakou si přejete přihodit ke stávající aukci.</Typography>
        <Typography>Nová cena:{' '}
          <Typography component='span' sx={{ fontWeight: 'bold' }}>{
            parseCurrencyAmount(Number(props.auction.currentAmount) + Number(value), props.supply.currency.label)
          }</Typography>
        </Typography>

        <TextField
          sx={{ mt: 2 }}
          value={value}
          fullWidth
          label='Hodnota příhozu'
          onClick={() => setValue('')}
          onChange={(ev) => {
            setValue(ev.currentTarget.value);
          }}
          name="numberformat"
          id="formatted-numberformat-input"
          error={!!validatedValue}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && !validatedValue) {
              userTriggeredBidButton();
            }
          }}
          helperText={typeof validatedValue === 'string' ? validatedValue : ' '}
          InputProps={{
            inputComponent: Currency as any, endAdornment: <InputAdornment position='end'>{getSymbolFromCurrency(props.supply.currency.label)}</InputAdornment>,
          }}
          variant="filled"
        />
      </DialogContent>
      <DialogActions sx={{
        justifyContent: 'space-between', px: 3,
      }}>
        <Fade in={showCountDown}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' >
            <Typography sx={{ fontWeight: 500 }} variant='body2'>Zbývající čas:</Typography>
            <Typography sx={{
              fontWeight: 500, textAlign: 'right', pl: 1,
            }} variant='body1'>{countDown}</Typography>
          </Stack>
        </Fade>
        <Stack direction='row' justifyContent='space-between'>
          <Button onClick={() => setOpen(false)}>Zavřít</Button>
          <LoadingButton loading={loading} variant='contained' color='secondary' onClick={userTriggeredBidButton} disabled={!!validatedValue}>Potvrdit příhoz</LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  </>);
}