import {
  DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  ButtonBase,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useNavigate, useParams  } from 'react-router-dom';
import { decode } from 'pluscodes';
import { useSnackbar } from 'notistack';
import theme from '../../../../theme';
import { Helmet } from 'react-helmet';
import useSupply from '../../../../Hooks/useSupply';
import useAuction from '../../../../Hooks/useAuction';
import useObjectTypes from '../../../../Hooks/useObjectTypes';
import useOrganizations from '../../../../Hooks/useOrganizations';
import { useKeycloak } from '@react-keycloak/web';
import ReactQuill from 'react-quill';
import GoogleMapsInput from '../../../../GoogleMapsInput';
import { DateTimeField, DateTimePicker } from '@mui/x-date-pickers';
import { Currency } from '../../../../InputMask/Currency';
import ObchodniPodminkyDialog from '../../../../ObchodniPodminky/ObchodniPodminkyDialog';
import { ToSAgreed } from '../../../../InputValidator';
import {
  AddRounded, Delete, PhotoCamera, Upload,
} from '@mui/icons-material';
import useContractTypes from '../../../../Hooks/useContractTypes';
import useAPI, { Form, Supply } from '../../../../Hooks/useAPI';
import useCurrencyTypes from '../../../../Hooks/useCurrencyTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { cloneDeep } from 'lodash';
import { CSS } from '@dnd-kit/utilities';

const modules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [ { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'], /* "video" */
    ['clean'], // remove formatting button
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image',
];

function SortableItem(props: {
  id: string,
  supplyId: string,
  isDragging: boolean,
  image: ({file: File, url: string}) | string,
  previewMultimediaUuid: undefined | string,
  onRemove: () => void }) {
  const { getSupplyMultimedia } = useAPI();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity:   props.isDragging ? 0.5 : 1,
    transition,
  };

  return (
    <Grid item key={props.id}
      ref={setNodeRef} style={style} {...attributes}
      draggable
      xs={2}
      alignSelf='center'
      sx={{
        position: 'relative',
        cursor:   'pointer',
      }}
    >
      {typeof props.image !== 'string' && <Box sx={{
        position: 'absolute', right: '30px', top: '30px', p: '8px',
      }}>
        <Upload htmlColor='white'/>
      </Box>}
      <IconButton sx={{
        position: 'absolute', right: 0, top: '30px',
      }} onClick={props.onRemove}>
        <Delete htmlColor='#e02804'/>
      </IconButton>

      <img style={{
        width:     '100%',
        height:    '110px',
        objectFit: 'contain',

      }}
      {...listeners}
      src={typeof props.image !== 'string' ? props.image.url : getSupplyMultimedia(props.supplyId, props.id)} alt=''/>
      <Box sx={{
        backgroundColor: '#99000022',
        textAlign:       'center',
        fontWeight:      'bold',
        visibility:      props.id !== props.previewMultimediaUuid ? 'hidden' : 'visible',
      }}>Náhledový obrázek</Box>

    </Grid>
  );
}

function checkDateisValid(date: dayjs.Dayjs | null){
  if (date) {
    return date.isValid();
  } else {
    return false;
  }
}

const filterForm1 = ['form=1', 'form=2', 'form=3'];
const filterForm2 = ['form=4'];

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const contractTypes = useContractTypes();
  const objectTypes = useObjectTypes();
  const currencyTypes = useCurrencyTypes();

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { uploadSupplyMultimedia, removeSupplyMultimedia, getOrganization } = useAPI();

  const fileInput = React.useRef<HTMLInputElement>(null);

  const [ multimediaUuidToDelete, setMultimediaUuidToDelete ] = React.useState<string[]>([]);

  const { supplyId } = useParams();
  const supply = useSupply(supplyId);
  const isInsolvencySelected = supply.data?.contractType.id === 3;
  const auction = useAuction(supplyId, supply.data?.currentAuctionUuid);
  const { data: organizations } = useOrganizations({
    pageSize: 100, page: null, filters: !isInsolvencySelected ? filterForm1 : filterForm2,
  });
  const [ images, setImages ] = React.useState<(string | { file: File, url: string })[]>([]);
  const [activeId, setActiveId] = React.useState<null | string>(null);

  const [ durationRange, setDurationRange ] = React.useState(0);

  const [ ToSChecked, setToSChecked ] = React.useState<string | null>(supplyId === 'create' ? null : 'true');
  const ToSValid = ToSAgreed(ToSChecked);

  const [ saving, setSaving ] = React.useState<boolean>(false);
  const [ error, setError ] = React.useState<any>(null);

  const [ imageError, setImageError ] = React.useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  React.useEffect(() => {
    setImages(supply.data?.multimediaUuids ?? []);
  }, [ supply?.data?.multimediaUuids ]);

  function handleDragEnd(event: { active: any; over: any; }) {
    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    if (active.id !== over.id) {
      setImages(list => {
        const newIndex = list.findIndex(it => typeof it === 'string' ? it === over.id : it.file.name === over.id);
        const oldIndex = list.findIndex(it => typeof it === 'string' ? it === active.id : it.file.name === active.id);
        return arrayMove(list, oldIndex, newIndex);
      });
    } else {
      supply.update('previewMultimediaUuid', active.id);
    }
    setActiveId(null);
  }

  function handleDragStart(event: { active: any; }) {
    const { active } = event;
    setActiveId(active.id);
  }

  const [forceInsolvency, setForceInsolvency] = React.useState(false);
  const [ loading, setLoading ] = React.useState(true);
  React.useEffect(() => {
    if (contractTypes.length === 0) {
      return;
    }
    if (keycloak.hasResourceRole('showOrganizationOperator') && keycloak.tokenParsed?.sub) {
      setLoading(true);
      getOrganization().then(res => {
        supply.update('organizationUuid', res.uuid!);
        setForceInsolvency(res.form.id === 4);
        const contractType = contractTypes.find(o => o.id === (res.form.id === 4 ? 3 : 1));
        if (contractType) {
          supply.update('contractType', contractType);
        }
      })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [ keycloak.authenticated, contractTypes ]);

  const isAuctionActive = React.useMemo(() => {
    if (auction.data && supplyId !== 'create') {
      return dayjs().isAfter(auction.data.startTimestamp) && dayjs().isBefore(auction.data.endTimestamp);
    } else {
      return false;
    }
  }, [auction, supplyId]);

  const isAuctionDone = React.useMemo(() => {
    if (auction.data && supplyId !== 'create') {
      return dayjs().isAfter(auction.data.endTimestamp);
    } else {
      return false;
    }
  }, [auction, supplyId]);

  React.useEffect(() => {
    if (!auction.data) {
      return;
    }

    if (durationRange === 0 && supplyId) {
      const diff = dayjs(auction.data.endTimestamp).diff(dayjs(auction.data.startTimestamp), 'days');
      // we don't sometimes get correct times
      if (diff <= 1) {
        setDurationRange(1);
      } else if (diff <= 3) {
        setDurationRange(3);
      } else if (diff <= 7) {
        setDurationRange(7);
      } else if (diff <= 14) {
        setDurationRange(14);
      } else {
        // set maximum range to 30
        setDurationRange(30);
      }
    } else if (durationRange === 0) {
      setDurationRange(7);
    } else {
      if (dayjs(auction.data.startTimestamp).isValid()) {
        // change ending time as durationRange is valid
        const val = dayjs(auction.data.startTimestamp).add(durationRange, 'days').subtract(1, 'seconds').toISOString();
        if (val !== auction.data.endTimestamp) {
          auction.update('endTimestamp', val);
        }
      } else {
        auction.update('endTimestamp', '');
      }
    }
  }, [ durationRange, auction, supplyId ]);

  const removeImage = (idx: number) => {
    const image = images[idx];
    if (typeof image === 'string') {
      setMultimediaUuidToDelete(o => [...o, image]);
    }
    setImages(list => list.filter((_, lIdx) => lIdx !== idx));
  };

  const uploadFileTrigger = React.useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  const handleUpload = (files: FileList | null) => {
    if (files) {
      setImageError(false);

      for (const file of Array.from(files)) {
        if (!['image/png', 'image/jpeg'].includes(file.type) || file.size / 1024 > 2048) {
          setImageError(true);
        }
      }
      setImages(list => {
        if (list.length === 0) {
          supply.update('previewMultimediaUuid', files[0].name);
        }
        return [
          ...list,
          ...Array.from(files)
            .filter(file => ['image/png', 'image/jpeg'].includes(file.type) && file.size / 1024 <= 2048)
            .map(o => ({
              file: o,
              url:  URL.createObjectURL(o),
            })),
        ];
      });
    }
  };

  const handleImageUpload = async (imageList: typeof images, sId: string) => {
    const handleImages = cloneDeep(imageList);
    for (let idx = 0; idx < handleImages.length; idx++) {
      const image = handleImages[idx];
      if (typeof image === 'string') {
        continue;
      }
      const uuid = await uploadSupplyMultimedia(sId, image.file);
      supply.appendMultimedia(uuid);
      if (image.file.name === supply.data?.previewMultimediaUuid) {
        supply.update('previewMultimediaUuid', uuid);
      }
      handleImages[idx] = uuid;
    }
    supply.update('multimediaUuids', handleImages as string[]);
    await supply.updateImages(handleImages as string[]); // resave for images
  };

  const handleSave = React.useCallback(async () => {
    if (!auction.data || !supply.data) {
      console.error('Auction or supply data missing', {
        auction: auction.data, supply: supply.data,
      });
      return;
    }

    if (ToSChecked === null) {
      setToSChecked('false');
    }

    console.log('Auction valid:', auction.dirtifyAndValidate());
    console.log('Supply valid:', supply.dirtifyAndValidate());
    if (
      auction.dirtifyAndValidate() && supply.dirtifyAndValidate() && ToSChecked === 'true'
    ) {
      setSaving(true);

      // create supply
      try {
        // remove images
        for (const image of multimediaUuidToDelete) {
          await removeSupplyMultimedia(supply.data.uuid!, image);
        }

        if (supplyId === 'create') {
          const saved = await supply.save();
          const auctionId = await auction.save(saved);
          await supply.assignAuction(auctionId);
          await handleImageUpload(images, saved.uuid!);
          await supply.assingPreviewMultimedia();
          enqueueSnackbar('Aukce byla vytvořena.', { variant: 'success' });
          navigate(`/administrace/aukce`);
        } else {
          console.log('Updating', supplyId);
          handleImageUpload(images, supply.data.uuid!);
          await auction.save(supply.data as Required<Supply>);
          await supply.save();
          enqueueSnackbar('Aukce byla editována.', { variant: 'success' });
        }
        setError(null);
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Nepodařilo se provést akci.', { variant: 'error' });
        setError(e);
      }
      setSaving(false);
    }
  }, [ auction, supply, navigate, enqueueSnackbar, ToSChecked, images, multimediaUuidToDelete ]);

  return (<Container sx={{
    marginTop:     isMobile ? 0 : 1,
    paddingBottom: 1,
    pt:            '30px',
  }}>
    <Box>
      <Helmet>
        <link rel='canonical' href={location.origin}/>
        <title>{supplyId === 'create'
          ? 'Administrace - vytvoření aukce'
          : 'Administrace - editace aukce'
        } - { process.env.REACT_APP_TITLE }</title>
      </Helmet>
      <Typography variant="h1" component="h1">
        {supplyId === 'create'
          ? <>Vytvoření <span>aukce</span></>
          : <>Editace <span>aukce</span></>
        }
      </Typography>
      { auction.loaded === null || supply.loaded === null
        ? <Alert severity='error' variant='filled' sx={{ mt: 5 }}>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
        : <>
          { !auction.data || !supply.data || loading
            ? <Box sx={{
              height:       '420px',
              display:      'flex',
              alignItems:   'center',
              placeContent: 'center',
            }}>
              <CircularProgress/>
            </Box>
            : <Box>
              {(isAuctionActive || isAuctionDone) && <Alert severity='error' icon={false} variant='filled' sx={{
                borderRadius:          '90px',
                width:                 'fit-content',
                margin:                'auto',
                backgroundColor:       '#e02804',
                height:                '40px',
                '& .MuiAlert-message': {
                  height:     '40px',
                  lineHeight: '12px',
                  mx:         '40px',

                },
              }}>
                {isAuctionActive
                  ? 'Pozor! Editujete probíhající aukci.'
                  : 'Pozor! Editujete ukončenou aukci.'
                }
              </Alert>}
              {error && <Alert severity='error' variant='filled' sx={{ my: 2 }}>Něco se pokazilo, opakujte později. { String(error) }</Alert>}

              <Box sx={{ pt: isMobile ? 2 : 8 }}/>
              <Stack spacing={2}>
                <TextField
                  variant='standard'
                  required
                  fullWidth
                  value={supply.data.title}
                  label="Název aukce"
                  {...supply.validate('title')}
                  // @ts-expect-error component attribute is not properly typed https://github.com/mui/material-ui/issues/33339
                  FormHelperTextProps={{ component: 'div' }}
                  helperText={<Stack direction='row' justifyContent='space-between' component='div'>
                    <div>
                      {supply.validate('title').helperText}
                    </div>
                    <div>
                      {(supply.data.title || '').length}/100
                    </div>
                  </Stack>}
                  onChange={(ev) => supply.update('title', ev.currentTarget.value)}
                />
                <FormControl fullWidth variant='standard'>
                  <InputLabel id="demo-simple-select-label" shrink>Typ nabídky</InputLabel>
                  <Select
                    value={JSON.stringify(supply.data.contractType)}
                    onChange={(ev) => supply.update('contractType', JSON.parse((ev.target.value as string)) as Form)}
                    label="Typ nabídky">
                    {contractTypes
                      .filter(o => {
                        if (keycloak.hasResourceRole('showOrganizationOperator')) {
                          // filtrace insolvence
                          return forceInsolvency ? o.id === 3 : o.id !== 3;
                        } else {
                          // vratit vse pokud je to admin
                          return true;
                        }
                      })
                      .map(type => <MenuItem value={JSON.stringify(type)} key={type.id}>{type.label}</MenuItem>)}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant='standard'>
                  <InputLabel id="demo-simple-select-label">Typ {supply.data?.contractType.id === 3 ? 'majetku' : 'nemovitosti'}</InputLabel>
                  <Select
                    value={JSON.stringify(supply.data.objectType)}
                    onChange={(ev) => supply.update('objectType', JSON.parse((ev.target.value as string)) as Form)}
                    label="Typ objektu">
                    {objectTypes
                      .filter(o => supply.data?.contractType.id === 3 ? o.id >= 10 : o.id < 10)
                      .map(type => <MenuItem value={JSON.stringify(type)} key={type.id}>{type.label}</MenuItem>)}
                  </Select>
                </FormControl>

                {!keycloak.hasResourceRole('showOrganizationOperator') && <FormControl sx={{ pb: 2 }} fullWidth variant='standard'>
                  <InputLabel error={!!supply.validate('organizationUuid').helperText} required id="demo-simple-select-label">Zadavatel</InputLabel>
                  <Select
                    value={supply.data.organizationUuid}
                    {...supply.validate('organizationUuid')}
                    onChange={(ev) => supply.update('organizationUuid', ev.target.value as any)}
                    required
                    label="Zadavatel">
                    {organizations.map(type => <MenuItem value={type.uuid} key={type.uuid}>{type.title}</MenuItem>)}
                  </Select>
                  {supply.validate('organizationUuid').helperText && <FormHelperText error>{supply.validate('organizationUuid').helperText}</FormHelperText>}
                </FormControl>}

                <Box sx={{ '.ql-editor': { minHeight: '200px' } }}>
                  <FormLabel error={!!supply.validate('description').helperText} sx={{
                    fontWeight: 900, fontSize: '12px !important',
                  }}>Popis</FormLabel>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={supply.data.description}
                    onChange={(val: string) =>  supply.update('description', val as any)} />
                  {supply.validate('description').helperText
                    ? <FormHelperText error>{supply.validate('description').helperText}</FormHelperText>
                    : <FormHelperText sx={{ textAlign: 'right' }}>{(supply.data.description || '').length}/4000</FormHelperText>
                  }
                </Box>

                {!isInsolvencySelected && <GoogleMapsInput
                  label="Adresa"
                  value={supply.data.streetCode}
                  onChange={(placeId, plusCode, description, address) => {
                    supply.update('zipCode', address.zip ?? '');
                    supply.update('cityCode', address.city ?? '');
                    supply.update('stateCode', address.country ?? '');
                    supply.update('streetCode', address.street_address ?? '');
                    supply.update('routeCode', address.street ?? '');
                    supply.update('provinceCode', address.province ?? '');

                    const decoded = decode(plusCode);
                    supply.update('latitude', decoded?.latitude ?? 0);
                    supply.update('longitude', decoded?.longitude ?? 0);
                  }}
                  error={
                    supply.validate('zipCode').error
                    || supply.validate('cityCode').error
                    || supply.validate('stateCode').error
                    || supply.validate('streetCode').error
                    || supply.validate('routeCode').error
                    || supply.validate('provinceCode').error
                  }
                  helperText={
                    (supply.validate('zipCode').error
                    || supply.validate('cityCode').error
                    || supply.validate('stateCode').error
                    || supply.validate('streetCode').error
                    || supply.validate('routeCode').error
                    || supply.validate('provinceCode').error)
                      ? 'Tato položka je povinná'
                      : undefined
                  }
                />}
                <Grid container>
                  <Grid item md={5} xs={12} sx={{ pr: isMobile ? 0 : 2 }}>
                    <DateTimePicker
                      format="DD.MM.YYYY H:mm:ss"
                      label="Začátek aukce"
                      defaultValue={auction.data.startTimestamp === '' ? dayjs() : dayjs(auction.data.startTimestamp)}
                      onChange={(newValue) => {
                        if (checkDateisValid(newValue)) {
                          auction.update('startTimestamp', newValue?.toISOString() ?? '');
                        } else {
                          auction.update('startTimestamp', '');
                        }
                      }}
                      {...auction.validate('startTimestamp')}
                    />
                  </Grid>

                  <Grid item md={2} xs={12} sx={{
                    pt: isMobile ? 1 : 0, pr: isMobile ? 0 : 2,
                  }}>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel id="demo-simple-select-label" shrink>Délka trvání aukce (dny)</InputLabel>
                      <Select
                        value={durationRange}
                        onChange={(ev) => setDurationRange(Number(ev.target.value))}
                        label="Délka trvání aukce (dny)">
                        {[1,3,7,14,30].map(v => <MenuItem value={v} key={v}>{v}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={5} xs={12} sx={{ pt: isMobile ? 1 : 0 }}>
                    <DateTimeField
                      fullWidth
                      format="DD.MM.YYYY H:mm:ss"
                      variant='standard'
                      label="Konec aukce"
                      value={auction.data.endTimestamp === '' ? null : dayjs(auction.data.endTimestamp)}
                      InputProps={{
                        readOnly: true, disabled: true,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs sx={{
                    pt: isMobile ? 1 : 0, pr: isMobile ? 0 : 2,
                  }}>
                    <TextField
                      defaultValue={auction.data.startingAmount}
                      fullWidth
                      label='Počáteční cena'
                      {...auction.validate('startingAmount')}
                      onChange={(ev) => auction.update('startingAmount', Number(ev.currentTarget.value))}
                      name="numberformat"
                      id="formatted-numberformat-input"
                      InputProps={{ inputComponent: Currency as any }}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item md={2} xs={12} sx={{
                    pt: isMobile ? 1 : 0, pr: isMobile ? 0 : 2,
                  }}>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel id="demo-simple-select-label">Měna</InputLabel>
                      <Select
                        value={JSON.stringify(supply.data.currency)}
                        onChange={(ev) => supply.update('currency', JSON.parse((ev.target.value as string)) as Form)}
                        label="Měna">
                        {currencyTypes.map(type => <MenuItem value={JSON.stringify(type)} key={type.id}>
                          { getSymbolFromCurrency(type.label) }
                        </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 4 }} spacing={4}>
                  <Grid item sx={{ pl: '0 !important' }}>
                    <Stack direction='row' spacing={2} alignItems={'center'}>
                      <PhotoCamera htmlColor='#e02804' sx={{ fontSize: '30px' }}/>
                      <FormLabel sx={{ fontWeight: 500 }}>Galerie</FormLabel>
                    </Stack>
                  </Grid>

                  <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                  >
                    <SortableContext
                      items={images.map(o => typeof o === 'string' ? o : o.file.name)}
                      strategy={rectSortingStrategy}
                    >
                      {images
                        .filter(o => typeof o !== 'string' || !multimediaUuidToDelete.includes(o))
                        .map((id, idx) => <SortableItem
                          supplyId={supplyId!}
                          previewMultimediaUuid={supply.data?.previewMultimediaUuid ?? ''}
                          image={id}
                          isDragging={typeof id === 'string' ? id === activeId : id.file.name === activeId}
                          id={typeof id !== 'string' ? id.file.name : id}
                          onRemove={() => removeImage(idx)}
                        />
                        )}
                    </SortableContext>
                  </DndContext>
                  <Grid item xs={2}>
                    <ButtonBase sx={{
                      border:       '4px dashed grey',
                      borderRadius: '30px',
                    }} onClick={uploadFileTrigger}>
                      <input hidden accept="image/*" multiple type="file" id="uploadInput" ref={fileInput} onChange={(ev) => handleUpload(ev.currentTarget.files)} />
                      <Card sx={{
                        justifyContent:    'center',
                        width:             '140px',
                        height:            '140px',
                        display:           'flex',
                        backgroundColor:   'transparent',
                        '&.MuiPaper-root': {
                          border: '0 !important', boxShadow: 'none !important',
                        },
                      }}
                      >
                        <AddRounded sx={{
                          fontSize: '100px', alignSelf: 'center',
                        }} htmlColor='#e02804'/>
                      </Card>
                    </ButtonBase>
                  </Grid>
                </Grid>

                <Typography>Podpora: jpeg/png do velikosti 2MB.
                  {' '}
                  <Typography
                    component='span'
                    sx={{ color: supply.validate('previewMultimediaUuid').error ? `${theme.palette.error.main} !important` : undefined }}>Po kliknutí na obrázek zvolte náhledový obrázek.</Typography>
                </Typography>
                {imageError && <Typography sx={{
                  color: 'red !important', mt: '0 !important',
                }}>Nahrajte soubor do 2 MB (2048 kB) ve formátu jpeg/png.</Typography>}
                <Box sx={{ pt: 4 }}>
                  <FormControl>
                    <RadioGroup
                      value={supply.data.isPublic}
                      onChange={(ev) => supply.update('isPublic', ev.currentTarget.value === 'true')}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Veřejná aukce" />
                      <FormControlLabel value={false} control={<Radio />} label="Neveřejná aukce" />
                    </RadioGroup>
                  </FormControl>
                </Box>

                { supplyId === 'create' && <FormControl
                  required
                  {...ToSValid === true ? {} : ToSValid}
                  component="fieldset"
                  sx={{
                    m: 3, py: 3,
                  }}
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={(ToSChecked ?? 'false') === 'true'} onChange={(_, checked) => setToSChecked(checked ? 'true' : 'false')} name="ToS" />
                      }
                      label={<Typography>
      Souhlasím s <ObchodniPodminkyDialog>obchodními podmínkami</ObchodniPodminkyDialog> *
                      </Typography>}
                    />
                  </FormGroup>

                  { ('helperText' in (ToSValid === true ? {} : ToSValid)) && <FormHelperText>{(ToSValid === true ? {} : ToSValid).helperText}</FormHelperText>}
                </FormControl>
                }
              </Stack>

              <Box sx={{
                margin: 'auto', pt: 4,  textAlign: 'center',
              }}>
                <LoadingButton loading={saving} onClick={handleSave} color='secondary' variant='contained' sx={{
                  height: '50px', width: '150px', borderRadius: '90px !important',
                }}>Uložit</LoadingButton>
              </Box>
            </Box>
          }
        </>
      }
    </Box>
  </Container>
  );
}
