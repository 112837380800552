import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Auction, Supply } from '../../../Hooks/useAPI';
import React, { useMemo, useState } from 'react';
import { Alert } from '@mui/lab';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { useIntervalWhen } from 'rooks';
import { useQuery } from '../../../Hooks/useQuery';
import { selectIsAuctionProcessing } from '../../../store/apiSlice';
import theme from '../../../theme';
import HistoryDialog from '../HistoryDialog/HistoryDialog';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ShareButton } from '../../../ShareButton/ShareButton';
import styles from './Stav.module.css';
import BidDialog from '../Bid/Bid';
import { parseCurrencyAmount } from '../../../parseCurrencyAmount';

dayjs.extend(duration);

type Props = {
  supply: Supply;
  auction: Auction,
  onBidError: () => void,
};

const countdownThresholdSeconds = 20;

export default function Stav({ auction, onBidError, supply }: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const params = useQuery();
  const [ countDown, setCountDown ] = useState('');
  const [ countDownTimestamp, setCountDownTimestamp ] = useState(0);
  const { keycloak } = useKeycloak();
  const isAuctionProcessing = useSelector(selectIsAuctionProcessing);

  React.useEffect(() =>{
    if (params.get('login')) {
      login();
    }
  }, [ params ]);

  const login = () => {
    if (params.get('iframe')) {
      window.open(`${window.location.href}&login=1`);
    }
    // get everything before ?
    history.pushState({}, '', new URL(window.location.href.split('?')[0]));
    keycloak.login();
  };

  useIntervalWhen(() => {
    const x = dayjs(auction.endTimestamp);
    const y = dayjs();

    const value = dayjs.duration(x.diff(y));

    const output = [
      value.days() > 0 ? `${value.days()}d` : null,
      value.hours() > 0 ? `${value.hours()}h` : null,
      value.minutes() > 0 ? `${value.minutes()}m` : null,
      `${value.seconds()}s`,
    ].filter(Boolean).join(' ');
    setCountDown(output);
    setCountDownTimestamp(value.asMilliseconds());
  }, 1000, true, true);

  const auctionStarted = useMemo(() => {
    const created_at_milisecs = (new Date(auction.startTimestamp)).getTime();
    const now_milisecs = (new Date()).getTime();
    if (now_milisecs > created_at_milisecs){
      return true;
    }
    return false;
  }, [auction]);

  const auctionEnded = useMemo(() => {
    return dayjs().isAfter(dayjs(auction.endTimestamp));
  }, [auction]);

  const isUserBidHighest = useMemo(() => {
    if (auction.bids.length === 0 || !keycloak.idTokenParsed) {
      return false;
    }

    return auction.bids[0].isSelf;
  }, [keycloak.idTokenParsed, auction.bids]);

  const isBiddingAvailable = keycloak.hasResourceRole('bidAuction');

  return (<>
    <Box sx={{
      height: '100%', position: 'relative',
    }}>
      {!isTablet && <ShareButton/>}
      <Typography variant="h2" component="h2" className={styles.header} sx={{ pt: 10 }}>Stav aukce</Typography>

      <Stack spacing={1} pt={2}>
        {(auctionStarted && !auctionEnded || isAuctionProcessing) && <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Zbývající čas aukce:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>
            {isAuctionProcessing && <CircularProgress size={18}/>} {countDown}</Typography>
        </Stack>}

        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Aktuální cena:</Typography>
          <Typography sx={{
            fontWeight: 900, color: '#e62f0f !important', textAlign: 'right',
          }}>{ parseCurrencyAmount(auction.currentAmount, supply.currency.label) }</Typography>
        </Stack>

        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Začátek aukce:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>{dayjs(auction.startTimestamp).format('D. M. YYYY - HH:mm:ss')}</Typography>
        </Stack>

        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Konec aukce:</Typography>
          <Typography sx={{
            fontWeight: 900, color: '#e62f0f !important', textAlign: 'right',
          }}>{dayjs(auction.endTimestamp).format('D. M. YYYY - HH:mm:ss')}</Typography>
        </Stack>
      </Stack>

      <Typography variant="h2" component="h2" className={styles.header} sx={{ pt: 4 }}>Detail aukce</Typography>

      <Stack spacing={1} pt={2}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Vyvolávací cena:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>{ parseCurrencyAmount(auction.startingAmount, supply.currency.label) }</Typography>
        </Stack>

        <Stack direction='row' justifyContent='space-between'>
          <Typography sx={{ fontWeight: 500 }}>Poslední příhoz:</Typography>
          <Typography sx={{
            fontWeight: 500, textAlign: 'right',
          }}>
            {
              auction.bids.length > 0
                ? dayjs(auction.bids[0].createTimestamp).format('D. M. YYYY - HH:mm:ss')
                : 'Zatím nikdo nepřihodil'
            }
          </Typography>
        </Stack>
        <Typography sx={{ 
          fontWeight: 300, fontSize: '16px !important', lineHeight: 1.2, marginTop: 15,
        }}>
          <Typography sx={{ 
            fontWeight: 300, fontSize: '16px !important', lineHeight: 1.2, color: '#e62f0f !important',
          }}>DOPORUČENÍ!</Typography> S ohledem na možné rozdíly v internetovém připojení a další okolnosti, doporučujeme účastníkům aukce provádět příhozy nejpozději 10 vteřin před koncem samotné aukce a při jejím prodloužení dle obchodních podmínek, tedy v <Typography sx={{ 
            fontWeight: 300, fontSize: '16px !important', lineHeight: 1.2, color: '#30ab4b !important',
          }}>zeleném poli grafického odpočtu.</Typography>
        </Typography>
      </Stack>

      {isAuctionProcessing
        ? <Alert variant='filled' severity='info' sx={{ mt: 2 }}>Aktuálně zpracováváme změny na aukci. Prosím vyčkejte.</Alert>
        : <>
          {(!auctionEnded && auctionStarted) && <>
            {
            // if is iframe
              params.get('iframe')
                ? <Typography sx={{ fontWeight: 500 }}>
                    Pro účast na aukci klikněte{' '}
                  <Link component='a' href={`${window.location.origin}${window.location.pathname}`} target="_blank" sx={{
                    fontWeight: 700, color: '#e62f0f !important', cursor: 'pointer',
                  }}>Zde</Link>
                </Typography>
              // if bidding is available and auction is in progress
                : isBiddingAvailable && <Stack pt={4} spacing={2}>
                  <BidDialog disabled={isUserBidHighest} supply={supply} auction={auction} onBidError={onBidError}/>
                </Stack> }
          </>}

          {auctionEnded && <Alert variant='filled' severity='info' sx={{ mt: 2 }}>Aukce již skončila. Děkujeme Vám za účast na aukci.</Alert>}
          {!auctionStarted && !auctionEnded && <Alert variant='filled' severity='info' sx={{ mt: 2 }}>Vyčkejte do začátku aukce.</Alert>}
        </>}

      {(auctionStarted && !auctionEnded && Math.floor(countDownTimestamp / 1000) <= countdownThresholdSeconds) && <ProgressBar percentage={(Math.floor(countDownTimestamp / 1000) / countdownThresholdSeconds) * 100}/>}

      {(!params.get('iframe') && !keycloak.authenticated && !auctionEnded) && <Stack pt={4} spacing={2}>
        <Typography sx={{ fontWeight: 500 }}>
              Pro přihození do aukce se prosím
          <Typography component='span' sx={{
            fontWeight: 700, color: '#e62f0f !important', cursor: 'pointer',
          }} onClick={login}> Přihlaste</Typography>
        </Typography>
      </Stack>}
    </Box>

    <Stack direction='row' spacing={2} justifyContent='center' sx={isMobile ? {} : {
      textAlign: 'center', transform: 'translateY(-4px)',
    }}>
      <HistoryDialog auction={auction} supply={supply}/>
      {isTablet && <Box sx={{
        position: 'relative', width: '120px',
      }}><ShareButton/></Box>}
    </Stack>
  </>);
}