import {
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  Alert, Box, CircularProgress, Container, Pagination, Typography, useMediaQuery,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { pagingPanelMessages, tableHeaderMessages } from '../../../../Grid/Localization';
import { PriceFormatter, PriceTypeProvider } from '../../../../Grid/PriceTypeProvider';
import useAuction from '../../../../Hooks/useAuction';
import useSupply from '../../../../Hooks/useSupply';
import theme from '../../../../theme';
import {
  Table as ResponsiveTable, Tbody, Td, Th, Thead, Tr,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { chunk, orderBy } from 'lodash';

export default function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { supplyId } = useParams();
  const supply = useSupply(supplyId);
  const auction = useAuction(supplyId, supply.data?.currentAuctionUuid);

  const { keycloak } = useKeycloak();

  // mobile pagination
  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [pageSizes] = React.useState([5, 10, 15, 25, 50, 0]);

  const columns = React.useMemo(() => [
    {
      title: 'Pořadí', name: 'order',
    },
    {
      title: 'Zákazník', name: 'customerName',
    },
    {
      title:        'Nabídka',
      name:         'amount',
      getCellValue: (_row: any) => {
        return {
          amount:   _row.amount,
          currency: supply.data?.currency.label ?? 'CZK',
        };
      },
    },
  ]
  , [keycloak.authenticated, supply.data]);

  const GenericFormatter = React.useCallback((props: { attr: string, value: string | boolean | string[], item: unknown }) => {
    if (['amount'].includes(props.attr)) {
      return PriceFormatter({
        value: {
          amount:   Number(props.value),
          currency: supply.data?.currency.label ?? 'CZK',
        },
      });
    } else {
      return <>{props.value}</>;
    }
  }, [supply.data]);

  return (<Container sx={{
    p: '30px !important', mt: 1,
  }}>
    <Helmet>
      <link rel='canonical' href={location.origin}/>
      <title>Administrace - příhozy - { process.env.REACT_APP_TITLE }</title>
    </Helmet>
    <Typography variant="h1" component="h1" sx={{ pl: '60px' }}>Administrace - <span>příhozy</span></Typography>
    { auction.loaded === null
      ? <Alert severity='error' sx={{ mt: 5 }} variant='filled'>Nemáte dostatečná oprávnění pro tuto stránku</Alert>
      : <>
        { !auction.loaded || !auction.data
          ? <Box sx={{
            height:       '420px',
            display:      'flex',
            alignItems:   'center',
            placeContent: 'center',
          }}>
            <CircularProgress/>
          </Box>
          : <Box sx={{ pt: 5 }}>
            {isMobile
              ? <Box sx={{ '& .responsiveTable td.pivoted': { paddingLeft: 'calc(35% + 10px) !important' } }}>
                <ResponsiveTable key={columns.length}>
                  <Thead>
                    <Tr>
                      {columns.map(o => <Th key={o.title}>{o.title}</Th>)}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {chunk(auction.data.bids, 5)[page - 1]?.map(o => <Tr key={o.uuid}>
                      {columns.map(c => <Td aria-label={c.title}>
                        <GenericFormatter item={o} attr={c.name} value={o[c.name as keyof typeof o] as any}/>
                      </Td>)}
                    </Tr>
                    )}
                  </Tbody>
                </ResponsiveTable>

                <Pagination sx={{
                  pt:                    2,
                  '& .MuiPagination-ul': {
                    p: '0 !important', justifyContent: 'center',
                  },
                  '& .MuiPaginationItem-page': { fontSize: '3vw !important' },
                }} siblingCount={1} count={Math.ceil(auction.data.bids.length / 5)} page={page} onChange={handleChange} />
              </Box>
              : <Grid
                key={columns.length}
                rows={orderBy(auction.data.bids, 'createTimestamp', 'desc').map((o, order) => ({
                  ...o, order: order + 1,
                }))}
                columns={columns}
              >
                <PriceTypeProvider
                  for={['amount']}
                />

                <SortingState
                  defaultSorting={[{
                    columnName: 'order', direction: 'asc',
                  }]}/>
                <PagingState
                  defaultCurrentPage={0}
                  defaultPageSize={10}
                />
                <IntegratedPaging />
                <Table messages={{ noData: 'Žádní přihazující' }}/>
                <TableHeaderRow showSortingControls messages={tableHeaderMessages}  />
                <PagingPanel
                  messages={pagingPanelMessages}
                  pageSizes={pageSizes}
                />
              </Grid>}
          </Box>
        }
      </>
    }
  </Container>
  );
}